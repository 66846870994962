


























import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import * as _ from "lodash";
import html2canvas from "html2canvas";
import { dataUrlToBlob } from "@/utils/base64ToBlob";
import { CalCuttedTongueArea } from "@/request/research";
import { UploadImage } from "@/request/common";
@Component({})
export default class IdrPicCanvas extends Vue {
  @Prop()
  private picSrc: any; // 当前图片
  @Watch("picSrc")
  private picSrcChange(src: any) {
    this.calCuttedTongueArea();
    this.initCanvas();
  }
  @Prop()
  private data: any; // 舌图信息
  @Prop()
  private picType: any; // origin、
  private cuttedArea: any = 0; // 分割后舌图的像素面积
  private canvasWrap: any = {};
  private hammer: any = {};
  private sourceImage: any = {
    imgObj: {},
    x: 0,
    y: 0,
    sourceX: 0, // 底图左上角X
    sourceY: 0, // 底图左上角y
    centerX: 0, // 底图中心点X
    centerY: 0, // 底图中心点X
    width: 0, // 底图宽
    height: 0, // 底图高
    originWidth: 0,
    originHeight: 0,
    rotateAngel: 0, // 旋转角
    moveX: 0,
    moveY: 0,
    scaleX: 1,
    scaleY: 1,
    aspectRadio: 1,
  };
  private canvas: any = {};
  private context: any = {};
  private model: any = "";
  private canvasCenterX: any = 0;
  private canvasCenterY: any = 0;
  // 操作值
  private startPoint: any = {};
  private endPoint: any = {};
  // 临时路径
  private tempPath: any[] = [];
  private colors: any = {
    黑睛: "red",
    上: "blue",
    下: "orange",
    左: "yellow",
    右: "green",
    左上: "#EA0AF6",
    左下: "#FEC9C9",
    右上: "#FF7473",
    右下: "#67D5B5",
  };
  private shetaiColor: string = "#EA0AF6";
  private ifMouseLeftDown: boolean = false;
  private ifMouseRightDown: boolean = false;
  // private get ossClient() {
  //   return this.$store.state.ossClient;
  // }
  private get t() {
    return this.data;
  }
  private set t(val) {
    this.$emit("update:data", val);
  }
  private get canvasClientX() {
    return (this.$refs.canvasWrap as any).getBoundingClientRect().left;
  }
  private get canvasClientY() {
    return (this.$refs.canvasWrap as any).getBoundingClientRect().top;
  }
  private getTransform() {
    return (
      "rotate(" +
      this.sourceImage.rotateAngel +
      "deg)" +
      " scale(" +
      this.sourceImage.scaleX +
      "," +
      this.sourceImage.scaleY +
      ")"
    );
  }
  /**
   * @description 获取分割后舌图的像素面积
   */
  private calCuttedTongueArea() {
    if (!this.picSrc.url) {
      return;
    }
    const params: any = {
      分割后舌图: this.picSrc.url,
    };
    CalCuttedTongueArea(this, params).then((data: any) => {
      this.cuttedArea = data["area"];
    });
  }
  /**
   * @description 初始化canvas和显示图片
   */
  private initCanvas() {
    this.canvasWrap = this.$refs.canvasWrap;
    this.canvas = this.$refs.canvas;
    this.canvas.width = this.canvasWrap.offsetWidth;
    this.canvas.height = this.canvasWrap.offsetHeight;
    this.context = this.canvas.getContext("2d");
    // 绘制黑色背景
    this.context.fillStyle = "black";
    this.context.fillRect(0, 0, this.canvas.width, this.canvas.height);
    this.drawImgInInitCanvas();
  }
  /**
   * @description 初始化绘制图片到canvas里
   */
  private drawImgInInitCanvas() {
    const imageObj: any = new Image();
    // 以下是在header中的带上Origin属性，没有在使用canvas的toBlob()、toDataURL()和getImageData()方法时就会出现跨域的问题
    imageObj.crossOrigin = "anonymous";
    imageObj.onload = (e: any) => {
      this.sourceImage.imgObj = imageObj;
      let dx: number = 0;
      let dy: number = 0;
      let dWidth: any = 0;
      let dHeight: any = 0;
      // 处理缩放
      this.sourceImage.originWidth = imageObj.width;
      this.sourceImage.originHeight = imageObj.height;
      const originAspectRatio: any = imageObj.width / imageObj.height;
      this.sourceImage.aspectRadio = originAspectRatio;
      if (imageObj.width >= this.canvas.width) {
        dWidth = this.canvas.width;
        dHeight = Math.round(dWidth / originAspectRatio);
        if (dHeight >= this.canvas.height) {
          dHeight = this.canvas.height;
          dWidth = Math.round(dHeight * originAspectRatio);
        }
      } else {
        dHeight = this.canvas.height;
        dWidth = Math.round(dHeight * originAspectRatio);
        if (dWidth >= this.canvas.width) {
          dWidth = this.canvas.width;
          dHeight = Math.round(dWidth / originAspectRatio);
        }
      }
      // 记录底图宽高
      this.sourceImage.width = dWidth;
      this.sourceImage.height = dHeight;
      // 处理居中
      dx = (this.canvas.width - dWidth) / 2;
      dy = (this.canvas.height - dHeight) / 2;
      // 记录底图左上角原点位置
      this.sourceImage.sourceX = this.sourceImage.x = dx;
      this.sourceImage.sourceY = this.sourceImage.y = dy;
      // 记录底图中心点
      this.sourceImage.centerX = dx + dWidth / 2;
      this.sourceImage.centerY = dy + dHeight / 2;
      // 旋转角
      if (this.t["轨迹"] && this.t["轨迹"]["旋转角"]) {
        this.sourceImage.rotateAngel = this.t["轨迹"]["旋转角"];
      }
      // 绘图
      this.context.drawImage(
        imageObj,
        this.sourceImage.x,
        this.sourceImage.y,
        dWidth,
        dHeight
      );
      this.drawPath();
    };
    imageObj.src = this.picSrc.url + "?t=" + new Date().getTime();
  }
  /**
   * @description 处理鼠标按下
   */
  private handleMouseDown(e: any) {
    this.handleDown(e, "pc端");
  }
  /**
   * @description 处理手指按下
   */
  private handleTouchStart(e: any) {
    this.handleDown(e, "移动端");
  }
  /**
   * @description 处理鼠标移动
   */
  private handleMouseMove(e: any) {
    this.handleMove(e, "pc端");
  }
  /**
   * @description 处理手指移动
   */
  private handleTouchMove(e: any) {
    this.handleMove(e, "移动端");
  }
  /**
   * @description 处理鼠标弹起
   */
  private handleMouseUp(e: any) {
    this.handleUp(e, "pc端");
  }
  /**
   * @description 处理手指弹起
   */
  private handleTouchEnd(e: any) {
    this.handleUp(e, "移动端");
  }
  /**
   * @description 处理鼠标（手指）按下
   */
  private handleDown(e: any, type: any) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    if (type === "pc端") {
      if (this.model !== "放大镜" && this.model !== "舌苔局部放大图") {
        if (e.button === 0) {
          this.ifMouseLeftDown = true;
        } else {
          return;
        }
      } else {
        if (e.button === 0) {
          this.ifMouseLeftDown = true;
        } else if (e.button === 2) {
          this.ifMouseRightDown = true;
        } else {
          return;
        }
      }
    }
    let X: any;
    let Y: any;
    if (type === "pc端") {
      this.startPoint = {
        offsetX: e.offsetX,
        offsetY: e.offsetY,
      };
      X = e.offsetX;
      Y = e.offsetY;
    } else if (type === "移动端") {
      this.startPoint = {
        offsetX: e.touches[0].clientX - this.canvasClientX,
        offsetY: e.touches[0].clientY - this.canvasClientY,
      };
      X = e.touches[0].clientX - this.canvasClientX;
      Y = e.touches[0].clientY - this.canvasClientY;
    }
    if (
      this.model == "黑睛" ||
      this.model == "上" ||
      this.model == "下" ||
      this.model == "左" ||
      this.model == "右" ||
      this.model == "左上" ||
      this.model == "左下" ||
      this.model == "右上" ||
      this.model == "右下"
    ) {
      this.context.save();
      this.context.beginPath();
      this.context.moveTo(this.startPoint.offsetX, this.startPoint.offsetY);
      this.context.strokeStyle = this.colors[this.model];
      this.tempPath = [];
    } else if (this.model == "放大镜") {
      if (this.ifMouseLeftDown === true) {
        if (!this.ifMouseRightDown) {
          this.draw();
          this.context.save();
          this.context.drawImage(
            this.sourceImage.imgObj,
            (X - 50 - (this.canvas.width - this.sourceImage.width) / 2) /
              (this.sourceImage.width / this.sourceImage.originWidth),
            (Y - 50 - (this.canvas.height - this.sourceImage.height) / 2) /
              (this.sourceImage.height / this.sourceImage.originHeight),
            100 / (this.sourceImage.width / this.sourceImage.originWidth),
            100 / (this.sourceImage.height / this.sourceImage.originHeight),
            X - 100,
            Y - 100,
            200,
            200
          );
          this.context.restore();
        }
      }
    }
  }
  /**
   * @description 处理鼠标(手指)移动
   */
  private handleMove(e: any, type: any) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    if (!this.startPoint.offsetX || !this.model) {
      return;
    }
    if (type === "pc端") {
      if (!this.ifMouseLeftDown) {
        return;
      }
    }
    let X: any;
    let Y: any;
    if (type === "pc端") {
      X = e.offsetX;
      Y = e.offsetY;
    } else if (type === "移动端") {
      X = e.touches[0].clientX - this.canvasClientX;
      Y = e.touches[0].clientY - this.canvasClientY;
    }
    this.drawBlackBack().then(() => {
      const blackWidth = (this.canvas.width - this.sourceImage.width) / 2;
      const blackHeight = (this.canvas.height - this.sourceImage.height) / 2;
      if (
        this.model == "黑睛" ||
        this.model == "上" ||
        this.model == "下" ||
        this.model == "左" ||
        this.model == "右" ||
        this.model == "左上" ||
        this.model == "左下" ||
        this.model == "右上" ||
        this.model == "右下"
      ) {
        this.draw();
        this.context.lineTo(X, Y);
        this.tempPath.push({
          offsetX: X - blackWidth,
          offsetY: Y - blackHeight,
        });
        this.context.stroke();
      } else if (this.model == "移动") {
        if (type === "pc端") {
          this.sourceImage.moveX += e.movementX;
          this.sourceImage.moveY += e.movementY;
        } else if (type === "移动端") {
          const movementX = e.touches[0].clientX - this.startPoint.offsetX;
          const movementY = e.touches[0].clientY - this.startPoint.offsetY;
          this.sourceImage.moveX = movementX;
          this.sourceImage.moveY = movementY;
        }
        this.draw();
        this.drawPath();
      } else if (this.model == "缩放") {
        const bigSpeed = 1.01;
        const smallSpeed = 0.99;
        let targetX;
        // let targetY;
        if (type === "pc端") {
          if (e.movementX > 0) {
            this.sourceImage.scaleX *= bigSpeed;
            targetX = bigSpeed;
          } else {
            this.sourceImage.scaleX *= smallSpeed;
            targetX = smallSpeed;
          }
        } else if (type === "移动端") {
          if (
            e.touches[0].clientY - this.canvasClientY >
            this.startPoint.offsetY
          ) {
            this.sourceImage.scaleX *= bigSpeed;
            targetX = bigSpeed;
          } else {
            this.sourceImage.scaleX *= smallSpeed;
            targetX = smallSpeed;
          }
        }
        this.sourceImage.scaleY = this.sourceImage.scaleX;
        this.draw();
        this.drawPath();
      } else if (this.model == "放大镜") {
        this.draw();
        this.context.save();
        this.context.drawImage(
          this.sourceImage.imgObj,
          (X - 50 - (this.canvas.width - this.sourceImage.width) / 2) /
            (this.sourceImage.width / this.sourceImage.originWidth),
          (Y - 50 - (this.canvas.height - this.sourceImage.height) / 2) /
            (this.sourceImage.height / this.sourceImage.originHeight),
          100 / (this.sourceImage.width / this.sourceImage.originWidth),
          100 / (this.sourceImage.height / this.sourceImage.originHeight),
          X - 100,
          Y - 100,
          200,
          200
        );
        this.context.restore();
      } else if (this.model == "旋转") {
        let rotateAngel = Math.PI / (6 * 1);
        if (e.movementY < 0) {
          rotateAngel = -rotateAngel;
        } else if (e.movementY === 0) {
          rotateAngel = 0;
        }
        this.sourceImage.rotateAngel += rotateAngel;
        this.t["轨迹"]["旋转角"] = this.sourceImage.rotateAngel;
        this.draw();
        this.drawPath();
      } else {
        this.draw();
        this.drawPath();
      }
    });
  }
  /**
   * @description 处理鼠标（手指）弹起
   */
  private handleUp(e: any, type: any) {
    e.preventDefault();
    e.stopPropagation();
    e.stopImmediatePropagation();
    if (type === "pc端") {
      if (!this.ifMouseLeftDown) {
        return;
      }
    }
    const blackWidth = (this.canvas.width - this.sourceImage.width) / 2;
    const blackHeight = (this.canvas.height - this.sourceImage.height) / 2;
    let X: any;
    let Y: any;
    if (
      this.model == "黑睛" ||
      this.model == "上" ||
      this.model == "下" ||
      this.model == "左" ||
      this.model == "右" ||
      this.model == "左上" ||
      this.model == "左下" ||
      this.model == "右上" ||
      this.model == "右下"
    ) {
      this.context.closePath();
      const tempStartPoint = JSON.parse(JSON.stringify(this.startPoint));
      tempStartPoint.offsetX -= blackWidth;
      tempStartPoint.offsetY -= blackHeight;
      this.context.save();
      this.drawBlackBack().then(async () => {
        this.context.clip();
        this.draw();
        const area = this.calDrawedArea();
        const imgData = (this.canvas as HTMLCanvasElement).toDataURL(
          "image/jpeg",
          1.0
        );
        // 限制只能选一张图片且只能标注一个,所以每次先清空
        this.t["轨迹"][this.model] = {};
        const obj: any = {
          startPoint: {
            offsetX: tempStartPoint.offsetX,
            offsetY: tempStartPoint.offsetY,
          },
          path: this.tempPath,
          width: this.sourceImage.width,
          height: this.sourceImage.height,
          area: (Number(area) / 100) * this.cuttedArea,
        };
        this.t["轨迹"][this.model] = {
          id: this.picSrc.url,
          data: obj,
        };
        this.$emit("changeBiaozhu", this.t["轨迹"]);
        this.tempPath = [];
        this.startPoint = {};
        this.context.restore();
        this.draw();
        this.drawPath();
      });
      this.context.restore();
    } else if (this.model == "放大镜") {
      this.drawBlackBack().then(() => {
        this.draw();
        this.drawPath();
      });
    }
    this.startPoint.offsetX = 0;
    this.startPoint.offsetY = 0;
    if (type === "pc端") {
      this.ifMouseLeftDown = false;
    }
    if (type === "pc端" && this.ifMouseRightDown) {
      this.ifMouseRightDown = false;
    }
  }
  /**
   * @description 处理鼠标右键弹出菜单
   */
  private handleContextMenu(e: any) {
    if (this.model === "放大镜" || this.model === "舌苔局部放大图") {
      e.preventDefault();
    }
  }
  /**
   * @description 计算两点连线的线段长
   */
  private calLineLength(point1: any, point2: any) {
    const value1 = Math.pow(point2.offsetX - point1.offsetX, 2);
    const value2 = Math.pow(point2.offsetY - point1.offsetY, 2);
    return Math.pow(value1 + value2, 0.5);
  }
  /**
   * @description 计算当前绘制面积
   */
  private calDrawedArea() {
    const imgData = this.context.getImageData(
      this.sourceImage.x,
      this.sourceImage.y,
      this.sourceImage.width,
      this.sourceImage.height
    );
    const pixels = imgData.data;
    const transPixels: any[] = [];
    for (let i = 0; i < pixels.length; i += 4) {
      if (!(pixels[i] === 0 && pixels[i + 1] === 0 && pixels[i + 2] === 0)) {
        transPixels.push(pixels[i + 3]);
      }
    }
    const area = ((transPixels.length / (pixels.length / 4)) * 100).toFixed(2);
    return area;
  }
  /**
   * @description 计算2条直线的夹角
   */
  private getJiaodu(point: any, centerPoint: any) {
    // 求出生成三角形的第三个点
    const point2: any = {
      offsetX: centerPoint.offsetX,
      offsetY: point.offsetY,
    };
    const maxLength = this.calLineLength(point, centerPoint);
    const minLength = this.calLineLength(point2, point);
    const arc = Math.asin(Number(minLength) / Number(maxLength));
    const angle = (arc * 180) / Math.PI;
    const part = Math.PI / 180;
    // 画弧
    this.context.beginPath();
    this.context.lineWidth = 1;
    this.context.strokeStyle = this.shetaiColor;
    if (point2.offsetX - point.offsetX > 0) {
      this.context.arc(
        centerPoint.offsetX,
        centerPoint.offsetY,
        maxLength / 2,
        part * 90,
        part * (90 + angle)
      );
    } else {
      this.context.arc(
        centerPoint.offsetX,
        centerPoint.offsetY,
        maxLength / 2,
        part * (90 - angle),
        part * 90
      );
    }

    this.context.stroke();
    this.context.closePath();
    return angle.toFixed(2);
  }
  // 黑睛，上，下，左，右，左上，左下，右上，右下
  private biaozhu(e: any) {
    this.model = e;
  }
  /**
   * @description 删除某个目图标注
   */
  private deleteEye(e: any) {
    this.drawBlackBack().then(() => {
      this.draw();
      this.drawPath();
    });
  }

  /**
   * @description 移动
   */
  private yidong() {
    this.model = "移动";
  }
  /**
   * @description 缩放
   */
  private suofang() {
    this.model = "缩放";
  }
  /**
   * @description 放大镜
   */
  private fangdajing() {
    this.model = "放大镜";
  }
  /**
   * @description 旋转
   */
  private xuanzhuan() {
    this.model = "旋转";
  }
  /**
   * @description 截屏
   */
  private jieping() {
    this.model = "截屏";
    html2canvas(this.canvasWrap).then((canvas: HTMLCanvasElement) => {
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const i = document.createElement("a");
      i.download = "舌象图片";
      i.href = imgData;
      document.body.appendChild(i);
      i.click();
      i.remove();
    });
  }
  /**
   * @description 还原
   */
  private huanyuan() {
    this.model = "还原";
    this.sourceImage.rotateAngel = 0;
    this.sourceImage.moveX = 0;
    this.sourceImage.moveY = 0;
    this.sourceImage.scaleX = 1;
    this.sourceImage.scaleY = 1;
    // this.t["轨迹"] = {};
    this.initCanvas();
  }
  /**
   * @description 画舌态的基线
   */
  private drawJixian() {
    // 画基线
    this.context.beginPath();
    this.context.strokeStyle = this.shetaiColor;
    this.context.moveTo(this.canvas.width / 2, 0);
    this.context.lineTo(this.canvas.width / 2, this.canvas.height);
    this.context.closePath();
    this.context.stroke();
    this.context.restore();
  }
  /**
   * @description 后续绘制
   */
  private draw() {
    this.context.drawImage(
      this.sourceImage.imgObj,
      this.sourceImage.x,
      this.sourceImage.y,
      this.sourceImage.width,
      this.sourceImage.height
    );
    // this.drawPath();
  }
  /**
   * @description 画轨迹
   */
  private drawPath() {
    this.drawPath2();
  }
  private drawPath2() {
    // 目图
    const arr: any = [
      "黑睛",
      "上",
      "下",
      "左",
      "右",
      "左上",
      "左下",
      "右上",
      "右下",
    ];
    arr.forEach((ele: any) => {
      if (
        this.t["轨迹"][ele] &&
        this.t["轨迹"][ele].id &&
        this.t["轨迹"][ele].id == this.picSrc.url
      ) {
        const data: any = [this.t["轨迹"][ele]["data"]];
        const arr: any[] = [];
        const orArr: any[] = [];
        let totalArea: any = 0;
        data.forEach((item: any) => {
          // 如果有数据需要先生成相关显示字段
          arr.push({
            area: item["area"].toFixed(4) + "px²",
          });
          orArr.push(Number(item["area"].toFixed(4)));
          totalArea += item["area"];

          const d = item;
          const dw = this.sourceImage.width / d.width;
          const dh = this.sourceImage.height / d.height;
          const blackWidth = (this.canvas.width - this.sourceImage.width) / 2;
          const blackHeight =
            (this.canvas.height - this.sourceImage.height) / 2;
          this.context.save();
          this.context.beginPath();
          this.context.moveTo(
            d.startPoint.offsetX * dw + blackWidth,
            d.startPoint.offsetY * dh + blackHeight
          );
          this.context.strokeStyle = this.colors[ele];
          d.path.forEach((path: any) => {
            this.context.lineTo(
              path.offsetX * dw + blackWidth,
              path.offsetY * dh + blackHeight
            );
            this.context.stroke();
          });
          this.context.closePath();
          this.context.stroke();
          this.context.restore();
        });
      }
    });
  }
  /**
   * @description 绘制黑色背景
   */
  private drawBlackBack() {
    this.context.save();
    return new Promise((resolve) => {
      this.context.fillRect(
        -this.canvas.width * 2,
        -this.canvas.height * 2,
        this.canvas.width * 5,
        this.canvas.height * 5
      );
      this.context.restore();
      resolve(true);
    });
  }
  private test(key: any) {
    alert(key);
  }

  /**
   * @description 初始化
   */
  private mounted() {
    this.calCuttedTongueArea();
    this.initCanvas();
    // 每次窗口大小改变时需要重新计算画布大小
    window.onresize = () => {
      this.initCanvas();
    };
  }
}
