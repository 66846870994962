






































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private biaozhuType: any;
  @Prop()
  private type: any;
  @Watch("type", { immediate: true })
  private typeChange() {
    this.detail =
      this.data[this.type == "left" ? "left_eye" : "right_eye"]["白睛脉络"];
  }
  @Prop()
  private data: any;
  @Watch("data", { immediate: true })
  private dataChange() {
    this.detail =
      this.data[this.type == "left" ? "left_eye" : "right_eye"]["白睛脉络"];
  }
  private detail: any = {
    位置: {
      位置: [],
    },
    color: {
      color: "",
    },
    形状: {
      形状: [],
    },
    走行: {
      走行: "",
    },
    特殊眼征: {
      特殊眼征: [],
    },
  };
  private typeColors: any = {
    黑睛: "red",
    上: "blue",
    下: "orange",
    左: "yellow",
    右: "green",
    左上: "#EA0AF6",
    左下: "#FEC9C9",
    右上: "#FF7473",
    右下: "#67D5B5",
  };
  private colors: any = ["深红", "鲜红", "淡红", "淡紫", "淡青"];
  private weizhi: any = [
    "上",
    "下",
    "左上",
    "右上",
    "左下",
    "右下",
    "左",
    "右",
  ];
  private xingzhuang: any = [
    "根部粗大",
    "曲张",
    "怒张",
    "延伸",
    "离断",
    "分叉",
    "隆起",
    "雾斑",
    "黑点黑圈",
    "螺旋状",
    "蜘蛛网状",
    "叶脉状",
    "横行血丝",
    "血脉贯通",
  ];
  private zouxing: any = ["走向瞳孔", "远离瞳孔"];
  private teshuyanzheng: any = ["巩膜胃征", "巩膜痔征", "巩膜肝炎征"];
  private keys: any = ["上", "下", "左", "右", "左上", "左下", "右上", "右下"];
  private pingfenTree: any = [
    {
      name: "脉络走向",
      children: [
        {
          name: "伸向瞳孔或离断",
          score: 3,
        },
        {
          name: "伸向其他",
          score: 2,
        },
        {
          name: "杂乱无章",
          score: 1,
        },
      ],
    },
    {
      name: "脉络大小",
      children: [
        {
          name: "根部粗大或较粗大",
          score: 3,
        },
        {
          name: "一般大",
          score: 2,
        },
        {
          name: "细小",
          score: 1,
        },
      ],
    },
    {
      name: "脉络颜色",
      children: [
        {
          name: "深红、绛红色",
          score: 3,
        },
        {
          name: "鲜红色",
          score: 2,
        },
        {
          name: "粉红色",
          score: 1,
        },
      ],
    },
    {
      name: "脉络弯曲度",
      children: [
        {
          name: "呈螺旋状或曲度大",
          score: 3,
        },
        {
          name: "蛇行状",
          score: 2,
        },
        {
          name: "较直或不规则",
          score: 1,
        },
      ],
    },
    {
      name: "脉络末端斑点",
      children: [
        {
          name: "斑块或大斑点",
          score: 3,
        },
        {
          name: "小斑点",
          score: 2,
        },
        {
          name: "无斑点",
          score: 1,
        },
      ],
    },
    {
      name: "黑睛的改变",
      children: [
        {
          name: "凹陷穹窿代谢环变化瞳孔异常",
          score: 3,
        },
        {
          name: "代谢环变化黑线或黑点",
          score: 2,
        },
        {
          name: "瞳孔异常",
          score: 1,
        },
      ],
    },
  ];
  private zhenduan: any = {};
  private pingfenKey: any = "";
  private visible: any = false;
  private deleteBiaozhu(key: any) {
    this.$emit("deleteBiaozhu", key);
  }
  private startBiaozhu(key: any, scoreData: any) {
    if (!scoreData || scoreData.total_score < 13) {
      this.$message.warning("诊断评分>12才能标注");
      return;
    }
    this.$emit("biaozhuBaijing", key);
  }
  private openPingfen(key: any) {
    this.pingfenKey = key;
    if (this.detail[key]["诊断评分"]) {
      this.zhenduan = JSON.parse(JSON.stringify(this.detail[key]["诊断评分"]));
    } else {
      this.zhenduan = {};
    }

    this.visible = true;
  }
  private sure() {
    if (!this.zhenduan["脉络走向"]) {
      this.$message.warning("脉络走向必选！");
      return;
    }
    if (!this.zhenduan["脉络大小"]) {
      this.$message.warning("脉络大小必选！");
      return;
    }
    if (!this.zhenduan["脉络颜色"]) {
      this.$message.warning("脉络颜色必选！");
      return;
    }
    if (!this.zhenduan["脉络弯曲度"]) {
      this.$message.warning("脉络弯曲度必选！");
      return;
    }
    if (!this.zhenduan["脉络末端斑点"]) {
      this.$message.warning("脉络末端斑点必选！");
      return;
    }
    if (!this.zhenduan["黑睛的改变"]) {
      this.$message.warning("黑睛的改变必选！");
      return;
    }
    // 算分
    let score: any = 0;
    this.pingfenTree.forEach((ele: any) => {
      ele.children.forEach((e: any) => {
        if (e.name == this.zhenduan[ele.name]) {
          score += e.score;
        }
      });
    });
    this.zhenduan.total_score = score.toString();
    this.detail[this.pingfenKey]["诊断评分"] = this.zhenduan;
    this.visible = false;
  }
  private valueChange() {
    const d = JSON.parse(JSON.stringify(this.data));
    d[this.type == "left" ? "left_eye" : "right_eye"]["白睛脉络"] = this.detail;
    this.$emit("change", d);
  }
}
