
































































































































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import {
  GetEyeTags,
  EditEyeTags,
  GetEyeDetail,
  GetEyeDetailZhenci,
  EditEyeDetail,
} from "@/request/research";
import PicCanvas from "./components/pic-canvas.vue";
import Fenge from "./components/fenge.vue";
import Jianyi from "./components/jianyi.vue";
import Heijing from "./components/heijing.vue";
import Baijing from "./components/baijing.vue";
@Component({
  components: {
    PicCanvas,
    Fenge,
    Jianyi,
    Heijing,
    Baijing,
  },
})
export default class IdrBiaozhu extends Vue {
  private projectId: any = "";
  private id: any = "";
  private options: any = {};
  private currentPicSrc: any = {};
  private pics: any = {
    left: [],
    right: [],
  };
  private detail: any = {
    l_eye_image: [],
    r_eye_image: [],
    专家建议: {},
    轨迹: {},
  };
  private data: any[] = [];
  private model: any = "";
  private picType: any = "origin";
  private cPart: any = "黑睛";
  private ifShowBidui: boolean = false;
  private loaded: boolean = false;
  private ifOpenFenge: boolean = false;
  private kuankuanbiHelp: boolean = false;
  private labels: any = [];
  private ifShowAddLabel: boolean = false;
  private tempLabels: any[] = [];
  private biaozhuType: any = "";
  private get picCanvas() {
    return this.$refs.picCanvas as any;
  }
  @Watch("$route.query", { immediate: true, deep: true })
  private queryChange() {
    this.id = this.$route.query.id;
    this.projectId = this.$route.query.project_id;
    if (this.$route.query.pType) {
      this.cPart = this.$route.query.pType;
    }
    this.fetchDetail().then(() => {
      this.fetchPatientAllTongue();
    });
  }
  private update(e: any) {
    this.detail = e;
    this.$forceUpdate();
  }
  /**
   * @description 跳转智能比对
   */
  private jumpBidui() {
    // localStorage.setItem("selectedTongues", JSON.stringify([this.detail]));
    // this.$router.push("/main/research/tongue/bidui-list");
  }
  /**
   * @description 保存
   */
  private save() {
    const params: any = JSON.parse(JSON.stringify(this.detail));
    EditEyeDetail(this, params).then((data: any) => {
      this.$message.success("标注成功");
      this.fetchDetail();
    });
  }
  /**
   * @description 获得该患者的全部图片
   */
  private fetchPatientAllTongue() {
    const params: any = {
      params: {
        patient_id: this.detail.patient_id,
        project_id: this.projectId,
      },
    };
    GetEyeDetailZhenci(this, params).then((data: any) => {
      this.data = data;
    });
  }
  /**
   * @description 获得详情
   */
  private fetchDetail() {
    this.loaded = false;
    return new Promise((resolve, reject) => {
      const params: any = {
        params: {
          eye_id: this.id,
        },
      };
      GetEyeDetail(this, params)
        .then((data: any) => {
          if (!data["轨迹"]) {
            data["轨迹"] = {};
          }
          // data['left_eye']['黑睛']['color']['color']=[];
          this.detail = data;
          this.biaozhuType = "";
          this.pics = {
            left: [],
            right: [],
          };
          if (this.detail.l_eye_image && this.detail.l_eye_image.length > 0) {
            this.detail.l_eye_image.forEach((ele: any, index: any) => {
              const obj = {
                type: "left",
                index: index,
                url: ele,
              };
              this.pics.left.push(obj);
            });
          }
          if (this.detail.r_eye_image && this.detail.r_eye_image.length > 0) {
            this.detail.r_eye_image.forEach((ele: any, index: any) => {
              const obj = {
                type: "right",
                index: index,
                url: ele,
              };
              this.pics.right.push(obj);
            });
          }
          if (this.pics.left.length > 0) {
            this.currentPicSrc = this.pics.left[0];
          } else {
            this.currentPicSrc = this.pics.right[0];
          }
          this.loaded = true;
          resolve(true);
        })
        .catch(() => {
          reject();
        });
    });
  }
  /**
   * @description 返回列表
   */
  private backList() {
    this.$router.push("/main/research/eye/list");
  }
  /**
   * @description 跳转图预览
   */
  private jumpPreview() {
    const params: any = JSON.parse(JSON.stringify(this.detail));
    EditEyeDetail(this, params).then((data: any) => {
      this.$router.push({
        path: "/main/research/eye/preview",
        query: {
          id: this.detail.eye_id,
        },
      });
    });
  }
  /**
   * @description 切换同一个患者的其他图
   */
  private changeZhenci(d: any) {
    this.$router.push({
      path: "/main/research/eye/biaozhu",
      query: {
        id: d.eye_id,
        project_id: this.projectId,
      },
    });
  }
  /**
   * @description 切换展示的图片
   */
  private changeCurrentPic(src: any, model: any) {
    this.currentPicSrc = src;
    this.picType = model;
  }
  // 图像标注
  private startBiaozhu(e: any) {
    this.picCanvas.biaozhu(e);
    this.biaozhuType = e;
  }
  private changeBiaozhu(e: any) {
    let d: any = JSON.parse(JSON.stringify(this.detail));
    d["轨迹"] = e;
    this.detail = d;
  }
  private deleteBiaozhu(e: any) {
    const d = JSON.parse(JSON.stringify(this.detail));
    d["轨迹"][e] = {};
    this.detail = d;
    this.picCanvas.deleteEye(e);
  }
  /**
   * @description 打开分割弹窗
   */
  private fenge() {
    this.ifOpenFenge = true;
  }
  // 图像操作
  /**
   * @description 移动
   */
  private yidong() {
    this.model = "移动";
    this.picCanvas.yidong();
  }
  /**
   * @description 缩放
   */
  private suofang() {
    this.model = "缩放";
    this.picCanvas.suofang();
  }
  /**
   * @description 放大镜
   */
  private fangdajing() {
    this.model = "放大镜";
    this.picCanvas.fangdajing();
    this.cPart = "望舌苔";
    this.$nextTick().then(() => {
      (this.$refs.shetai as any).jubushetaiScrollToView();
    });
  }
  /**
   * @description 旋转
   */
  private xuanzhuan() {
    this.model = "旋转";
    this.picCanvas.xuanzhuan();
  }
  /**
   * @description 截屏
   */
  private jieping() {
    this.model = "截屏";
    this.picCanvas.jieping();
  }
  /**
   * @description 还原
   */
  private huanyuan() {
    this.model = "还原";
    this.picCanvas.huanyuan();
  }
  /**
   * @description 改变model
   */
  private modelChange(model: any) {
    this.model = model;
  }
  /**
   * @description 切换显示的部分
   */
  private changePart(part: any) {
    this.cPart = part;
    this.biaozhuType = "";
  }
  /**
   * @description 获得可选的标签列表
   */
  private fetchBiaoqianList() {
    return new Promise((resolve, reject) => {
      GetEyeTags(this)
        .then((data: any) => {
          this.labels = data;
          resolve(true);
        })
        .catch(() => {
          reject();
        });
    });
  }
  /**
   * 当前标签是否被选中
   */
  private getHas(name: any) {
    let result = false;
    if (this.detail["labels"] && this.detail["labels"].length > 0) {
      this.detail["labels"].forEach((ele: any) => {
        if (ele["tag_name"] === name) {
          result = true;
        }
      });
    }
    return result;
  }
  /**
   * @description 删除当前舌图标签
   */
  private delOpt(i: any) {
    this.detail["labels"].splice(i, 1);
  }
  /**
   * @description 选择舌图标签
   */
  private selOne(item: any) {
    if (this.getHas(item["名称"])) {
      return;
    }
    this.detail["labels"].push(item);
  }
  /**
   * @description 打开添加标签弹窗
   */
  private openAddLabel() {
    this.tempLabels = JSON.parse(JSON.stringify(this.labels));
    if (this.tempLabels.length === 0) {
      this.$set(this.tempLabels, "0", { tag_name: "" });
    }
    this.ifShowAddLabel = true;
  }
  /**
   * @description 关闭弹窗
   */
  private closeAddLabel() {
    this.ifShowAddLabel = false;
  }
  /**
   * @description 刷新页面
   */
  private refresh() {
    this.fetchBiaoqianList().then(() => {
      this.filterSelectedLables();
    });
  }
  /**
   * @description 根据新的标签列表，筛选已选标签
   */
  private filterSelectedLables() {
    this.detail["labels"].forEach((item: any, index: any) => {
      this.labels.forEach((i: any) => {
        if (i.tag_id === item.tag_id) {
          this.detail["labels"].splice(index, 1);
        }
      });
    });
  }
  /**
   * @description 添加临时标签
   */
  private addLabel() {
    this.tempLabels.unshift({ tag_name: "" });
  }
  /**
   * @description 删除一个标签
   */
  private deleteLabel(index: any) {
    if (this.tempLabels.length === 1) {
      this.tempLabels = [{ tag_name: "" }];
    } else {
      this.tempLabels.splice(index, 1);
    }
  }
  /**
   * @description 保存对标签的修改
   */
  private saveAddedLabel() {
    const params: any = {
      tags: this.tempLabels,
    };
    EditEyeTags(this, params).then(() => {
      this.$message.success("修改成功");
      this.ifShowAddLabel = false;
      this.tempLabels = [];
    });
  }
  /**
   * @description 初始化
   */
  private mounted() {
    this.fetchBiaoqianList();
  }
}
